.App {
  background-image: linear-gradient(#0065bf, white);
  text-align: center; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Dashboard {
  margin: auto;
  width: 60%; 
}

.Dashboard-logo {
  margin: 2%;
  height: 10vmin;
  pointer-events: none; 
}

.Dashboard-header {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.Center-border {
  background-color: white;
  margin: auto; 
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 0.5%;
}

label, span {
  overflow-wrap: anywhere;
}

header {
  color: navy;
  font-size: 20px;
  padding-bottom: 5px;
}

.Container {
  background-color: white;
  display: flex;
  flex-wrap: wrap; 
  row-gap: 10px;
  column-gap: 70px;
  align-items: baseline;
}

.Other-container { 
  background-color: white;
  display: flex;  
  row-gap: 10px;
  column-gap: 0px;
}

.table {
  table-layout: fixed;
  max-width: 320px;
  border-collapse: collapse;
  border-spacing:20px 20px;
  white-space: nowrap
}

.break {
  flex-basis: 100%;
}

.control-width {
  max-width: 780px; 
}

.other-width {
  max-width: 1100px;
}

.item {
  padding-left: 0%; 
}

td:nth-last-child(1) {
  text-align: right;
}

.flex-direction{
  flex-direction:row;
}

@media screen and (max-width: 1500px) {
  .flex-direction {
    flex-direction:column;
  }

  .item {
    padding-left: 0%; 
  }
}

.main {
  background-image: linear-gradient(#E8F1F9, #69A4D9);
  /* background-color: #EEEEEE; */
}

.shadow {
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
} 

.command-button {
  width: 15%;
  margin-left: 2%;
  border-radius: 1%;
}

input {
  border: none;
  border: 1px solid lightgray;
  border-radius: 1%;
}

.testClasss {
  minWidth: 200px;
}